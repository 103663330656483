import React from 'react';

const FooterAppLinks = () => {
  return (
    <div className="py-3.5 px-[42px]  flex flex-col rounded ">
      <p
        className="text-16 font-semibold uppercase text-left mb-3.5"
        data-test-id="footer:footer-section-experience-mobile"
      >
        Experience decathlon app on mobile
      </p>
      <div className="flex">
        <a
          href="https://play.google.com/store/apps/details?id=com.evamall.evacustomer&hl=en_IN"
          target="_blank"
          className="no-underline"
          data-test-id="footer:footer-app-link-google-playstore"
        >
          <img
            src={require('../../../assets/img/google-play-badge.webp')}
            alt="Gplay"
            loading="lazy"
            className="max-w-[110px] w-full h-[36px]"
          />
        </a>
        <a
          href="https://apps.apple.com/in/app/decathlon-online-shopping-app/id1387810949"
          target="_blank"
          className="ml-2.5"
          data-test-id="footer:footer-app-link-appstore"
        >
          <img
            crossOrigin="anonymous"
            src={require('../../../assets/img/appstore.svg')}
            alt="AppStore"
            loading="lazy"
            className="max-w-[110px] w-full h-[36px]"
          />
        </a>
      </div>
    </div>
  );
};

export default FooterAppLinks;
