import React, { useState } from 'react';

const CountriesDropdown = ({ countries }) => {
  const [showCountries, setShowCountries] = useState(false);

  const toggleCountries = () => setShowCountries(!showCountries);

  return (
    <div className="relative cursor-pointer ml-3">
      <span onClick={toggleCountries} className="m-0 py-2 px-4 border border-solid border-grey-600 rounded">
        <span>{countries ? countries.length : ''}</span>
        <span className="ml-1" data-test-id="footer:footer-section-countries">
          Countries
        </span>
      </span>
      {showCountries && (
        <ul className="mt-2 p-0 z-50 absolute min-w-[97px] w-full h-[114px] overflow-auto rounded-b-sm border border-solid border-grey-200 border-t-0 bg-white">
          {countries?.length &&
            countries.map((link) => (
              <li key={link.url}>
                <a
                  className="p-1 block text-14 hover:bg-grey-100"
                  href={`${link.url}`}
                  data-test-id={`footer:footer-section-countries-title-${
                    link && link?.text && link.text.split(' ').join('').toLowerCase()
                  }`}
                >
                  {link.text}
                </a>
              </li>
            ))}
        </ul>
      )}
    </div>
  );
};

export default CountriesDropdown;
