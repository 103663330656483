import React from 'react';
const PromiseTabs = ({ content }) => {
  if (!content) {
    return null;
  }

  return (
    <div className="p-8 rounded bg-grey-50">
      <div>
        <div className="block md:hidden">
          <p className="m-0 font-semibold uppercase text-16 text-grey-700">{content.title}</p>
        </div>
        <div className="flex justify-between">
          <div className="pr-2.5 w-full hidden md:flex items-center">
            <p
              className="m-0 font-semibold uppercase text-16 text-grey-700"
              data-test-id="footer:footer-promise-section-heading"
            >
              {content.title}
            </p>
          </div>
          {content.promiseCards?.map((card, i) => (
            <div key={i} className="w-full ">
              <a href={`${card.imageHyperLink}`} target={card.targetUrl ? '_blank' : ''} className="flex items-center">
                <span className="mr-2">
                  <img
                    src={`${card.imageMediaUrl}?format=auto`}
                    alt={`${card.text}`}
                    className="max-w-[30px]"
                    loading="lazy"
                  />
                </span>
                <span
                  className="text-14 text-grey-700"
                  data-test-id={`footer:footer-promise-section-link-title-${
                    card && card?.text && card.text.split(' ').join('').toLowerCase()
                  }`}
                >
                  {card.text}
                </span>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default PromiseTabs;
