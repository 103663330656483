import React from 'react';
import VerticalAccordion from '../Header/components/verticalAccordion';
import PropTypes from 'prop-types';

const FooterLinksMobile = ({ footerData }) => {
  const { support, supportLinks, ourServices, ourServicesLink, aboutUs, aboutUsLinks, legal, legalLinks } = footerData;
  const footerLinks = [
    { name: support, data: supportLinks },
    { name: ourServices, data: ourServicesLink },
    { name: aboutUs, data: aboutUsLinks },
    { name: legal, data: legalLinks },
  ];
  return (
    <>
      {footerLinks &&
        footerLinks.map((footer) => (
          <VerticalAccordion key={footer?.name} heading={footer?.name} className="bg-grey-50 md:bg-transparent">
            <div className="h-min-[10px] overflow-hidden">
              {footer?.data &&
                footer?.data?.map((link) => (
                  <a
                    key={link}
                    data-test-id={`footer:footer-mobile-section-link-text-${
                      link && link?.text && link.text.split(' ').join('').toLowerCase()
                    }`}
                    href={link?.url}
                    className="block pt-2 text-14 text-grey-700 cursor-pointer"
                  >
                    {link?.text}
                  </a>
                ))}
            </div>
          </VerticalAccordion>
        ))}
    </>
  );
};

FooterLinksMobile.propTypes = {
  footerData: PropTypes.any,
};

export default FooterLinksMobile;
