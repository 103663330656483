/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { Transition } from '@headlessui/react';
import Image from 'next/image';

const VerticalAccordion = ({ id, heading, className, children, icon, iconUrl, checkBox, cardType, cardTypeImg }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div className="mb-3">
      <div
        className={`p-4 cursor-pointer font-bold overflow-hidden rounded ${className}`}
        data-accordion-active={isOpen}
        style={{ cursor: 'pointer' }}
      >
        <div onClick={toggle} className="flex items-center justify-between cursor-pointer ">
          {checkBox ? <span className="check" /> : ''}
          {/* {icon ? (
						<span>
							<img src={require(`../../../assets/img/${iconUrl}`)} />
						</span>
					) : (
						''
					)} */}
          <span
            className="text-14"
            data-test-id={`footer:footer-mobile-section-link-title-${
              heading && heading.split(' ').join('').toLowerCase()
            }`}
          >
            {heading}
          </span>
          <span data-test-id="product:product-page-multiproduct-arrow-icon">
            <Image
              className={`${
                isOpen ? ' trasistion ease-out duration-500 rotate-180' : ' trasistion ease-out duration-500 rotate-0'
              }`}
              src={require('../../../assets/img/down_arrow.svg')}
              alt="Down"
              data-down-icon="true"
              width={10.653}
              height={5.679}
            />
          </span>
          {/* {cardType ? (
						<span className="inline-block float-right">
							<img src={require(`../../../assets/img/${cardTypeImg}`)} alt="Down" />
						</span>
					) : (
						''
					)} */}
        </div>
      </div>
      <div className="px-4 overflow-hidden rounded bg-grey-50 md:bg-white">
        <Transition
          show={isOpen}
          enter="transition ease-out duration-300 transform"
          enterFrom="-translate-y-full"
          enterTo="translate-y-0"
          leave="transition  ease-in duration-200 transform"
          leaveFrom="translate-y-0"
          leaveTo="-translate-y-full"
        >
          <div className={`overflow-hidden menu_dropdown${id}`}>{children}</div>
        </Transition>
      </div>
    </div>
  );
};

export default VerticalAccordion;
