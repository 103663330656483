import React from 'react';
import PropTypes from 'prop-types';

const FooterLinks = ({ footerData }) => {
  const { support, supportLinks, ourServices, ourServicesLink, aboutUs, aboutUsLinks, legal, legalLinks } = footerData;

  const footerLinks = [
    { name: support, data: supportLinks },
    { name: ourServices, data: ourServicesLink },
    { name: aboutUs, data: aboutUsLinks },
    { name: legal, data: legalLinks },
  ];

  return (
    <div className="flex justify-between p-5">
      {footerLinks &&
        footerLinks.map((footer) => (
          <div key={footer?.name}>
            <h6
              className="font-semibold uppercase text-14 text-grey-700"
              data-test-id={`footer:footer-section-link-title-${
                footer && footer?.name && footer?.name.split(' ').join('').toLowerCase()
              }`}
            >
              {footer?.name}
            </h6>
            <div>
              {footer?.data &&
                footer?.data?.map((link) => (
                  <div key={link.url}>
                    <a
                      href={link.url}
                      className="block w-full leading-loose text-14 text-grey-700"
                      data-test-id={`footer:footer-section-link-text-${
                        link && link?.text && link.text.split(' ').join('').toLowerCase()
                      }`}
                    >
                      {link.text}
                    </a>
                  </div>
                ))}
            </div>
          </div>
        ))}
    </div>
  );
};

FooterLinks.propTypes = {
  footerData: PropTypes.any,
};

export default FooterLinks;
