/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import CountriesDropdown from './CountriesDropdown';
import { Desktop, Mobile } from '../common/CustomMediaQuery';
import { VpSparklesIcon } from '@vtmn-play/icons/react';

const PaymentMethod = (props) => {
  return (
    <div className="mt-7 lg:mt-0 p-0 lg:pr-8 lg:pl-5 lg:py-8 block lg:flex items-center justify-between border-0 lg:border-t lg:border-b border-solid border-grey-200">
      <div className="pb-5 lg:pb-0 flex lg:border-0 border-b border-solid border-grey-400">
        <VpSparklesIcon size={48} />
        <div>
          <span
            className="mb-0 text-14 text-grey-700 uppercase font-bold"
            data-test-id="footer:footer-section-secure-transaction-heading"
          >
            100% Secure transaction
          </span>
          <p className="mt-1.5 mb-0 text-12 lg:text-14 text-grey-400">Secure SSL encryption</p>
        </div>
      </div>
      <div className="mt-5 lg:mt-0 flex flex-wrap">
        <img
          className="mr-3 mt-1 max-w-full"
          src={require('../../assets/img/debit.svg')}
          alt="Debit"
          loading="lazy"
          data-test-id="footer:footer-section-secure-transaction-debit"
        />
        <img
          className="mr-3 mt-1 max-w-full"
          src={require('../../assets/img/credit.svg')}
          alt="Credit"
          loading="lazy"
          data-test-id="footer:footer-section-secure-transaction-credit"
        />
        <img
          className="mr-3 mt-1 max-w-full"
          src={require('../../assets/img/upi.svg')}
          alt="UPI"
          loading="lazy"
          data-test-id="footer:footer-section-secure-transaction-upi"
        />
        <img
          className="max-w-full mt-1"
          src={require('../../assets/img/net_banking.svg')}
          alt="Net Banking"
          loading="lazy"
          data-test-id="footer:footer-section-secure-transaction-net-banking"
        />
      </div>
      <Desktop>
        <div>
          <a
            href="/sitemap"
            className="py-2 px-3.5 block border-l border-r border-solid border-grey-200 text-14 text-grey-700"
            data-test-id="footer:footer-section-site-map"
          >
            Site Map
          </a>
        </div>
        <div className="flex items-center text-14" data-test-id="footer:footer-section-decathlon-presence">
          DECATHLON is present in
          <CountriesDropdown countries={props.countries} />
        </div>
      </Desktop>
      <Mobile>
        <div className="mt-8">
          <p className="text-14 font-semibold">Manufactured/Imported By</p>
          <p className="text-[13px]">
            Decathlon Sports India Private Limited - Survey No. 78/10, A2 - 0 Chikkajala Village, Bellary Road,
            Bangalore - 562157, KA, IN.
          </p>
          <p className="mt-4 text-14 font-semibold">For Consumer Complaints/Assistance</p>
          <p className="text-[13px]">
            Please contact the above importer address or Tel: +91 - 7676798989 ; Email: care.india@decathlon.com
          </p>
        </div>
      </Mobile>
    </div>
  );
};
export default PaymentMethod;
