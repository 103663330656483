import React, { useMemo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { chatBotTrigger } from '@/src/Utils';
import PromiseTabs from '../Floors/PromiseTabs/promiseTabs';
import FooterAppLinks from '../Floors/PromiseTabs/FooterAppLink';
import PaymentMethod from '../Footer/PaymentMethod';
import { Desktop, Mobile, Default } from './CustomMediaQuery';
import VerticalAccordion from '../Header/components/verticalAccordion';

const ChatBot = dynamic(() => import('@/src/components/common/ChatBot'), { ssr: false });

import FooterLinksMobile from '../Footer/FooterLinksMobile';
import FooterLinks from '../Footer/FooterLinks/FooterLinks';
const FollowUs = dynamic(() => import('../Footer/FollowUs'), {
  ssr: false,
});
const FooterAdviceLink = dynamic(() => import('../Footer/FooterAdviceLink'), {
  ssr: false,
});

const getShowChatbotValue = (footerData, pathname) => {
  let chatbotState = false;
  if (footerData) {
    chatbotState = chatBotTrigger(footerData.chatBotTime, footerData.chatBotTrigger, pathname);
  }
  return chatbotState;
};

const Footer = () => {
  const router = useRouter();
  const footerWCMSData = useSelector((state) => state.reducer.footerWCMSData);
  const device = useSelector((state) => state.reducer.device);
  const showChatBot = useMemo(() => getShowChatbotValue(footerWCMSData, router?.pathname), [footerWCMSData]);

  if (!footerWCMSData) {
    return null;
  }

  return (
    <div className="relative mt-6">
      <footer className="px-4 w-full absolute z-[-1] md:static" data-test-id="footer:footer-page-content-wrapper">
        <div>
          <Desktop>
            <div className="flex flex-wrap">
              <div className="w-full pr-0 sm:pr-1 lg:w-9/12">
                {footerWCMSData && <PromiseTabs content={footerWCMSData.footerPromise} />}
              </div>
              {device === 'WEB' && (
                <div className="flex w-full pl-1 lg:w-3/12 bg-grey-50">
                  <FooterAppLinks />
                </div>
              )}
            </div>
          </Desktop>
          <div className="flex flex-wrap mt-0 lg:mt-2">
            <div className="w-full xl:w-9/12">
              {footerWCMSData && (
                <>
                  <Desktop>
                    <FooterLinks footerLinks={footerWCMSData.footerLinks} footerData={footerWCMSData} />
                  </Desktop>
                  <Mobile>
                    <FooterLinksMobile footerLinks={footerWCMSData.footerLinks} footerData={footerWCMSData} />
                  </Mobile>
                </>
              )}
              <div>
                <Default>
                  {footerWCMSData && device === 'WEB' && <PaymentMethod countries={footerWCMSData.countries} />}
                </Default>
              </div>
            </div>
            <div className="w-full xl:w-3/12">
              <Desktop>
                <div className="px-0 pt-4 rounded lg:px-10 lg:py-5 lg:bg-grey-50">
                  <div>
                    <FollowUs />
                  </div>
                  <FooterAdviceLink />{' '}
                </div>
              </Desktop>{' '}
              {device === 'WEB' && (
                <Mobile>
                  <div className="rounded discover_more bg-grey-50">
                    <VerticalAccordion heading="DISCOVER MORE" id="discover" className="bg-grey-50 md:bg-transparent">
                      <div className="px-0 pt-4 rounded lg:px-10 lg:py-5 lg:bg-grey-50">
                        <FooterAdviceLink />
                        <PaymentMethod />
                      </div>
                    </VerticalAccordion>
                  </div>

                  <div className="px-4 py-5 mt-4 rounded lg:mt-0 lg:py-0 lg:px-0 lg:rounded-none bg-grey-50 lg:bg-transparent">
                    <FollowUs />
                  </div>
                </Mobile>
              )}
            </div>
          </div>
        </div>
        <div className="relative overflow-hidden">
          <p
            className="my-4 text-center h-18 md:h-full text-12 text-grey-700"
            data-test-id="footer:footer-section-copyrights"
          >
            {footerWCMSData.copyrights}
          </p>
        </div>
      </footer>
      {process?.env?.NEXT_PUBLIC_CHATBOT_ENABLE === 'true' && showChatBot && <ChatBot />}
    </div>
  );
};

export default Footer;
